import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {Observable} from "rxjs";

@Injectable()
export class BlMetadataService {

    basePath = '/metadata';

    constructor(private blRequestService: BlRequestService) {
    }

    public list(entityType, entityId, metadataType): Observable<any> {
        const path = `${this.basePath}/${entityId}?entityType=${entityType}&metadataType=${metadataType}`;
        return this.blRequestService.get(path);
    }

}
