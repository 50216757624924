import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {Observable} from "rxjs";
import {RequestStageDTO} from "../../models/UpdateBatchRequestTypes";

@Injectable()
export class BlStagesService {

    basePath = '/stages';

    constructor(private blRequestService: BlRequestService) {
    }

    public list(sampleID: string, workspace?: string): Observable<RequestStageDTO[]> {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/${sampleID}${workspaceFilter}`;
        return this.blRequestService.get(path);
    }

}
